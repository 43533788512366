import { graphql, StaticQuery } from "gatsby"
import parse, { attributesToProps, domToReact } from "html-react-parser"
import React from "react"
import { LocalStorageService } from "../../services/local-storage"
import { StoreContext } from "../../store"

const Announcement = () => (
  <StaticQuery
    query={graphql`
      {
        wp {
          overviewAnnouncements {
            overviewAnnouncements {
              patternsAnnouncementContent
              announcementBackgroundColor
              announcementLinkColor
              announcementTextColor
            }
          }
        }
      }
    `}
    render={data => {
      // Extract attribtutes lists from graphql fields
      const textColorAttributeList =
        data.wp.overviewAnnouncements?.overviewAnnouncements
          ?.announcementTextColor
      const linkColorAttributeList =
        data.wp.overviewAnnouncements?.overviewAnnouncements
          ?.announcementLinkColor
      const backgroundColorAttributeList =
        data.wp.overviewAnnouncements?.overviewAnnouncements
          ?.announcementBackgroundColor

      // the hex colors are the third item in each attribute list
      const textColorHex =
        textColorAttributeList?.length && textColorAttributeList[2]
          ? textColorAttributeList[2]
          : "#ffffff"
      const linkColorHex =
        linkColorAttributeList?.length && linkColorAttributeList[2]
          ? linkColorAttributeList[2]
          : "#ffffff"
      const backgroundColorHex =
        backgroundColorAttributeList?.length && backgroundColorAttributeList[2]
          ? backgroundColorAttributeList[2]
          : "##82c149"

      const announcementContent =
        data.wp.overviewAnnouncements?.overviewAnnouncements
          ?.patternsAnnouncementContent

      const { isAnnouncementActive, setAnnouncementState } =
        React.useContext(StoreContext)

      React.useEffect(() => {
        const localAnn = LocalStorageService.getValue("goodg_announcement")
        const btn = LocalStorageService.getValue("goodg_announcement_btn")
        // init. check to see if goodg_announcement exist
        if (
          !localAnn ||
          (announcementContent !== "" && announcementContent !== localAnn)
        ) {
          // set goodg_announcement value
          LocalStorageService.setValue(
            "goodg_announcement",
            announcementContent
          )
          setAnnouncementState(true)
          LocalStorageService.setValue("goodg_announcement_btn", true)
        } else {
          // goodg_announcement does exist

          // now check to see if they are not the same
          if (announcementContent !== localAnn) {
            // new announcement. show
            setAnnouncementState(true)
          } else {
            // they are the same.

            // check to see if close btn has clicked or not
            if (btn) {
              setAnnouncementState(true)
            } else {
              setAnnouncementState(false)
            }
          }
        }

        // no announcment. clear all values and hide
        if (announcementContent === "" || announcementContent === null) {
          LocalStorageService.clearValue("goodg_announcement")
          LocalStorageService.setValue("goodg_announcement_btn", false)
          setAnnouncementState(false)
        }
      }, [])

      const handleClick = () => {
        setAnnouncementState(false)
        LocalStorageService.setValue("goodg_announcement_btn", false)
      }

      return (
        <div
          className={`PageAnnouncement ${
            isAnnouncementActive ? "is-active" : ""
          }`}
          style={{
            color: `${textColorHex}`,
            backgroundColor: `${backgroundColorHex}`,
          }}
        >
          <a
            className="PageAnnouncement-close"
            onClick={() => handleClick()}
          ></a>
          <div className="Wrap">
            {isAnnouncementActive && (
              <span>
                {parse(announcementContent, {
                  replace: domNode => {
                    if (domNode.name === "a") {
                      let props = attributesToProps(domNode.attribs)
                      props.style = { color: `${linkColorHex}` }
                      return <a {...props}>{domToReact(domNode.children)}</a>
                    }
                  },
                })}
              </span>
            )}
          </div>
        </div>
      )
    }}
  ></StaticQuery>
)

export default Announcement
