export class LocalStorageService {
  static setValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static getValue(key) {
    let data = localStorage.getItem(key)

    if (data === null) {
      return null
    }

    try {
      data = JSON.parse(data)
    } catch (e) {
      console.log(e)
      return null
    }

    return data
  }

  static clearValue(key) {
    localStorage.removeItem(key)
  }

  static clearExcept(keep) {
    Object.keys(localStorage)
      .filter(key => key !== keep)
      .forEach(key => localStorage.removeItem(key))
  }

  static clearAll() {
    localStorage.clear()
  }
}
