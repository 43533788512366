import { graphql, Link, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import "./footer.css"

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        allWpMenu {
          edges {
            node {
              slug
              name
              locations
              id
              menuItems {
                nodes {
                  url
                  title
                  label
                  id
                  parentId
                }
              }
            }
          }
        }
        allWp {
          edges {
            node {
              overviewFooter {
                overviewFooter {
                  footerAddress
                  footerLogo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: AUTO
                          layout: FULL_WIDTH
                          placeholder: BLURRED
                        )
                      }
                    }
                    altText
                    slug
                  }
                }
              }
              overviewFooterDescription {
                ...OverviewFooterDescriptionQuery
              }
              overviewSocial {
                ...OverviewSocialQuery
              }
            }
          }
        }
      }
    `}
    render={data => {
      const footerMenus = data.allWpMenu.edges

      // Candidates Footer Menu
      const forCandidateMenu = footerMenus.filter(
        item => item.node.slug === "for-candidates"
      )
      const forCandidateMenuLocation = forCandidateMenu[0].node.locations[0]
        .toLowerCase()
        .replace("_", "-")
      const forCandidateMenuTitle = forCandidateMenu[0].node.name
      const forCandidateMenuId = forCandidateMenu[0].node.id
      const forCandidateMenuItems = forCandidateMenu[0].node.menuItems.nodes

      // Candidates Footer Menu
      const forEmployersMenu = footerMenus.filter(
        item => item.node.slug === "for-employers"
      )
      const forEmployersMenuLocation = forEmployersMenu[0].node.locations[0]
        .toLowerCase()
        .replace("_", "-")
      const forEmployersMenuTitle = forEmployersMenu[0].node.name
      const forEmployersMenuId = forEmployersMenu[0].node.id
      const forEmployersMenuItems = forEmployersMenu[0].node.menuItems.nodes

      // Quick Links Footer Menu
      const quickLinksMenu = footerMenus.filter(
        item => item.node.slug === "quick-links"
      )
      const quickLinksMenuLocation = quickLinksMenu[0].node.locations[0]
        .toLowerCase()
        .replace("_", "-")
      const quickLinksMenuTitle = quickLinksMenu[0].node.name
      const quickLinksMenuId = quickLinksMenu[0].node.id
      const quickLinksMenuItems = quickLinksMenu[0].node.menuItems.nodes

      // Connect With Us Footer Menu
      const connectWithUsMenu = footerMenus.filter(
        item => item.node.slug === "connect-with-us"
      )
      const connectWithUsMenuLocation = connectWithUsMenu[0].node.locations[0]
        .toLowerCase()
        .replace("_", "-")
      const connectWithUsMenuTitle = connectWithUsMenu[0].node.name
      const connectWithUsMenuId = connectWithUsMenu[0].node.id
      const connectWithUsMenuItems = connectWithUsMenu[0].node.menuItems.nodes

      // Legal Footer Menu
      const legalMenu = footerMenus.filter(item => item.node.slug === "legal")
      const legalMenuLocation = legalMenu[0].node.locations[0]
        .toLowerCase()
        .replace("_", "-")
      const legalMenuItems = legalMenu[0].node.menuItems.nodes

      // Overview Fields
      const footerLogo =
        data.allWp.edges[0].node.overviewFooter.overviewFooter.footerLogo
          .localFile.childImageSharp.gatsbyImageData
      const footerAlt =
        data.allWp.edges[0].node.overviewFooter.overviewFooter.footerLogo.alt
      const footerAddress =
        data.allWp.edges[0].node.overviewFooter.overviewFooter.footerAddress

      const footerAddressMarkup = () => {
        return { __html: `${footerAddress}` }
      }
      const footerDescription =
        data.allWp.edges[0].node.overviewFooterDescription
          .overviewFooterDescription.footerDescription
      const footerAccreditations =
        data.allWp.edges[0].node.overviewFooterDescription
          .overviewFooterDescription.footerAccreditations

      // Social Media links

      const socialLinks =
        data.allWp.edges[0].node.overviewSocial.overviewSocialMedia
          .patternsSocialMedia

      return (
        <footer className="Footer">
          <div className="Wrap">
            <div className="Grid with-12">
              <div className="is-3 is-12--small">
                <div className="Footer-logo">
                  <div className="Logo">
                    <Link to="/">
                      <GatsbyImage
                        image={getImage(footerLogo)}
                        alt={footerAlt}
                      />
                    </Link>
                  </div>
                </div>
                <p className="Footer-description">{footerDescription}</p>
                <hr />
              </div>

              <div className="is-6 is-12--small">
                <div className="Grid with-12">
                  <div className="is-4 is-12--small">
                    <ul
                      id="menu-candidates-footer"
                      className={`Footer-nav ${forCandidateMenuLocation}`}
                    >
                      <li
                        id={`menu-item-${forCandidateMenuId}`}
                        className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-${forCandidateMenuId}`}
                      >
                        <a>{forCandidateMenuTitle}</a>
                        <ul className="sub-menu">
                          {forCandidateMenuItems.map((candidateItem, i) => {
                            if (candidateItem.parentId !== null) {
                              return (
                                <li
                                  id={`menu-item-141-${candidateItem.id}`}
                                  className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-${candidateItem.id}`}
                                  key={i}
                                >
                                  {candidateItem?.url?.includes("https://") ? (
                                    <a href={candidateItem.url}>
                                      {candidateItem.label}
                                    </a>
                                  ) : (
                                    <Link to={candidateItem.url}>
                                      {candidateItem.label}
                                    </Link>
                                  )}
                                </li>
                              )
                            }
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div className="is-4 is-12--small">
                    <ul
                      id="menu-employers-footer"
                      className={`Footer-nav ${forEmployersMenuLocation}`}
                    >
                      <li
                        id={`menu-item-${forEmployersMenuId}`}
                        className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-${forEmployersMenuId}`}
                      >
                        <a>{forEmployersMenuTitle}</a>
                        <ul className="sub-menu">
                          {forEmployersMenuItems.map((employerItem, i) => {
                            if (employerItem.parentId !== null) {
                              return (
                                <li
                                  id={`menu-item-141-${employerItem.id}`}
                                  className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-${employerItem.id}`}
                                  key={i}
                                >
                                  <Link to={employerItem.url}>
                                    {employerItem.label}
                                  </Link>
                                </li>
                              )
                            }
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div className="is-4 is-12--small">
                    <ul
                      id="menu-quick-links-footer"
                      className={`Footer-nav ${quickLinksMenuLocation}`}
                    >
                      <li
                        id={`menu-item-${quickLinksMenuId}`}
                        className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-${quickLinksMenuId}`}
                      >
                        <a>{quickLinksMenuTitle}</a>
                        <ul className="sub-menu">
                          {quickLinksMenuItems.map((quickLinks, i) => {
                            if (quickLinks.parentId !== null) {
                              return (
                                <li
                                  id={`menu-item-141-${quickLinks.id}`}
                                  className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-${quickLinks.id}`}
                                  key={i}
                                >
                                  <a href={quickLinks.url} target="_blank">
                                    {quickLinks.label}
                                  </a>
                                </li>
                              )
                            }
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="is-3 is-12--small">
                <ul
                  id="menu-connect-with-us-footer"
                  className={`Footer-nav ${connectWithUsMenuLocation}`}
                >
                  <li
                    id={`menu-item-${connectWithUsMenuId}`}
                    className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-${connectWithUsMenuId}`}
                  >
                    <a>{connectWithUsMenuTitle}</a>
                    <ul className="sub-menu">
                      {connectWithUsMenuItems.map((connectLinks, i) => {
                        const connectLinksMarkup = () => {
                          return { __html: `${connectLinks}` }
                        }
                        if (connectLinks.parentId !== null) {
                          return (
                            <li
                              id={`menu-item-141-${connectLinks.id}`}
                              className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-${connectLinks.id}`}
                              key={i}
                            >
                              {connectLinks.url?.includes("tel:") ? (
                                <a
                                  href={connectLinks.url}
                                  dangerouslySetInnerHTML={{
                                    __html: connectLinks.label,
                                  }}
                                  id={connectLinks.id}
                                />
                              ) : (
                                <Link
                                  to={connectLinks.url}
                                  id={connectLinks.id}
                                  dangerouslySetInnerHTML={{
                                    __html: connectLinks.label,
                                  }}
                                />
                              )}
                            </li>
                          )
                        }
                      })}
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="is-3 is-12--small">
                <p>
                  <div className="Footer-address">
                    <p dangerouslySetInnerHTML={footerAddressMarkup()} />
                  </div>
                </p>
                <p>© {new Date().getFullYear()} GoodHire</p>
              </div>

              <div className="is-6 is-12--small Accreditations">
                <div className="Grid with-3">
                  {footerAccreditations.map((icon, i) => {
                    const AccreditationsLink = icon.linkUrl
                    const AccreditationsImage = icon.image.localFile.publicURL
                    return (
                      <div key={i}>
                        <a
                          href={AccreditationsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={AccreditationsImage}
                            alt="icon"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="is-3 is-12--small">
                <div className="SocialMedia">
                  <ul>
                    {socialLinks.map((slinks, i) => {
                      const socialName = slinks.fieldGroupName
                      let socialLink = ""
                      {
                        if (slinks.patternsSocialMediaLinkedinInUrl) {
                          socialLink = slinks.patternsSocialMediaLinkedinInUrl
                        } else if (slinks.patternsSocialMediaFacebookFUrl) {
                          socialLink = slinks.patternsSocialMediaFacebookFUrl
                        } else if (slinks.patternsSocialMediaTwitterUrl) {
                          socialLink = slinks.patternsSocialMediaTwitterUrl
                        }
                      }
                      return (
                        <li className={`is-${socialName}`}>
                          <a
                            href={socialLink}
                            title={socialName}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`footer-icon icon-${socialName}`}
                          ></a>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <ul
                  id="menu-legal-footer"
                  className={`Footer-nav ${legalMenuLocation}`}
                >
                  {legalMenuItems.map((legalItem, i) => {
                    return (
                      <li
                        id={`menu-item-141-${legalItem.id}`}
                        className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-${legalItem.id}`}
                        key={i}
                      >
                        <Link to={legalItem.url}>{legalItem.label}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </footer>
      )
    }}
  ></StaticQuery>
)

export default Footer
