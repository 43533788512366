import { graphql, Link, StaticQuery } from "gatsby"
import * as React from "react"

const FooterMinimal = () => (
  <StaticQuery
    query={graphql`
      {
        allWpMenu {
          edges {
            node {
              slug
              name
              locations
              id
              menuItems {
                nodes {
                  url
                  title
                  label
                  id
                  parentId
                }
              }
            }
          }
        }
        allWp {
          edges {
            node {
              overviewFooter {
                overviewFooter {
                  footerAddress
                  footerLogo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: AUTO
                          layout: FULL_WIDTH
                          placeholder: TRACED_SVG
                        )
                      }
                    }
                    altText
                    slug
                  }
                }
              }
              overviewFooterDescription {
                ...OverviewFooterDescriptionQuery
              }
            }
          }
        }
      }
    `}
    render={data => {
      const footerMenus = data.allWpMenu.edges

      // Get Started Menu
      const getStartedMenu = footerMenus.filter(
        item => item.node.slug === "get-started-footer"
      )
      const getStartedMenuLocation = getStartedMenu[0].node.locations[0]
        .toLowerCase()
        .replace("_", "-")
      const getStartedMenuItems = getStartedMenu[0].node.menuItems.nodes

      return (
        <footer className="Footer FooterMinimal">
          <div className="Wrap">
            <div className="Grid with-12">
              <div className="is-12">
                <ul
                  id="menu-get-started-footer"
                  className={`Footer-nav ${getStartedMenuLocation}`}
                >
                  {getStartedMenuItems.map((getStartedItem, i) => {
                    return (
                      <li
                        id={`menu-item-141-${getStartedItem.id}`}
                        className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-${getStartedItem.id}`}
                        key={i}
                      >
                        <Link to={getStartedItem.url}>
                          {getStartedItem.label}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </footer>
      )
    }}
  ></StaticQuery>
)

export default FooterMinimal
