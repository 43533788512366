import { graphql, Link, StaticQuery } from "gatsby"
import * as React from "react"

const FooterLandingPage = () => (
  <StaticQuery
    query={graphql`
      {
        allWpMenu {
          edges {
            node {
              slug
              name
              locations
              id
              menuItems {
                nodes {
                  url
                  title
                  label
                  id
                  parentId
                }
              }
            }
          }
        }
        allWp {
          edges {
            node {
              overviewFooter {
                overviewFooter {
                  footerAddress
                  footerLogo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: AUTO
                          layout: FULL_WIDTH
                          placeholder: TRACED_SVG
                        )
                      }
                    }
                    altText
                    slug
                  }
                }
              }
              overviewFooterDescription {
                ...OverviewFooterDescriptionQuery
              }
              overviewSocial {
                ...OverviewSocialQuery
              }
            }
          }
        }
      }
    `}
    render={data => {
      const footerMenus = data.allWpMenu.edges

      // Legal Footer Menu
      const legalMenu = footerMenus.filter(item => item.node.slug === "legal")
      const legalMenuLocation = legalMenu[0].node.locations[0]
        .toLowerCase()
        .replace("_", "-")
      const legalMenuItems = legalMenu[0].node.menuItems.nodes

      // Overview Fields

      const footerAccreditations =
        data.allWp.edges[0].node.overviewFooterDescription
          .overviewFooterDescription.footerAccreditations

      const socialLinks =
        data.allWp.edges[0].node.overviewSocial.overviewSocialMedia
          .patternsSocialMedia

      return (
        <footer className="Footer FooterLandingPage is-active">
          <div className="Wrap">
            <div className="Grid with-12">
              <div className="is-4 is-12--small Accreditations">
                <div className="Grid with-3">
                  {footerAccreditations.map((icon, i) => {
                    const AccreditationsLink = icon.linkUrl
                    const AccreditationsImage = icon.image.localFile.publicURL
                    return (
                      <div key={i}>
                        <a
                          href={AccreditationsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={AccreditationsImage}
                            alt="icon"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="is-8 is-12--small">
                <div className="FooterLandingPage-nav-social">
                  <div className="SocialMedia">
                    <ul>
                      {socialLinks.map((slinks, i) => {
                        const socialName = slinks.fieldGroupName
                        let socialLink = ""
                        {
                          if (slinks.patternsSocialMediaLinkedinInUrl) {
                            socialLink = slinks.patternsSocialMediaLinkedinInUrl
                          } else if (slinks.patternsSocialMediaFacebookFUrl) {
                            socialLink = slinks.patternsSocialMediaFacebookFUrl
                          } else if (slinks.patternsSocialMediaTwitterUrl) {
                            socialLink = slinks.patternsSocialMediaTwitterUrl
                          }
                        }
                        return (
                          <li key={i} className={`is-${socialName}`}>
                            <Link
                              to={socialLink}
                              title="LinkedIn"
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`footer-icon icon-${socialName}`}
                            ></Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>

                <div className="FooterLandingPage-nav-right">
                  <p>© {new Date().getFullYear()} GoodHire</p>
                  <ul
                    id="menu-legal-footer"
                    className={`Footer-nav ${legalMenuLocation}`}
                  >
                    {legalMenuItems.map((legalItem, i) => {
                      return (
                        <li
                          id={`menu-item-141-${legalItem.id}`}
                          className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-${legalItem.id}`}
                          key={i}
                        >
                          <Link to={legalItem.url}>{legalItem.label}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )
    }}
  ></StaticQuery>
)

export default FooterLandingPage
